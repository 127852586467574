<template>
  <div>
    <tab-headers class="mb-5">
      Manage dispute
      <template #text> Follow up on disputes here </template>
    </tab-headers>

    <div class="">
      <a-input
        v-model="searchKeyword"
        placeholder="Search..."
        @change="debounceSearch"
      ></a-input>
    </div>

    <div class="mt-5 bg-white p-2 rounded shadow">
      <a-table
        :row-key="(record) => record._id"
        :loading="gettingDispute"
        :columns="headers"
        :data-source="disputes"
        :pagination="pagination"
        @change="getDispute"
      >
        <span slot="action" slot-scope="record">
          <router-link
            class=""
            :to="`dispute/${record._id}?userState=complainant`"
            >View
          </router-link>
        </span>

        <span slot="complainant" slot-scope="complainant">
          <div>
            {{ complainant ? complainant.firstName : '' }}
            {{ complainant ? complainant.lastName : '' }}
          </div>
        </span>

        <span slot="respondent" slot-scope="respondent">
          <div>
            {{ respondent ? respondent.firstName : '' }}
            {{ respondent ? respondent.lastName : '' }}
          </div>
        </span>

        <span slot="status" slot-scope="record">
          <div
            :class="`
          border py-1 px-3 text-center rounded
            ${
              record.status === 'pending'
                ? 'bg-red-50 text-red-500 border-red-500'
                : 'bg-green-50 text-green-500 border-green-500'
            }`"
          >
            {{ record.status }}
          </div>
        </span>

        <span slot="paymentStatus" slot-scope="record">
          <div
            :class="`
          border py-1 px-3 text-center rounded
            ${
              record.paymentStatus === 'completed'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`"
          >
            {{ record.paymentStatus }}
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import disputeApi from '../../api/dispute'

const headers = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Name of Complaint',
    dataIndex: 'complainant',
    scopedSlots: { customRender: 'complainant' },
  },
  {
    title: 'Name of Respondent',
    dataIndex: 'respondent',
    scopedSlots: { customRender: 'respondent' },
  },
  {
    title: 'Payment Status',
    key: 'paymentStatus',
    scopedSlots: { customRender: 'paymentStatus' },
    sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

const data = []

export default {
  name: 'UserManagement',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      headers,
      data,
      gettingDispute: false,
      user: {},
      visible: false,
      actionOnUserLoading: false,
      disputes: [],
      searchKeyword: '',
      pagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0,
      },
    }
  },
  created() {
    this.getDispute(this.pagination)
    this.debounceSearch = _.debounce(this.searchDispute, 500)
  },
  methods: {
    async searchDispute() {
      if (this.searchKeyword === '') {
        this.getDispute(this.pagination)
        return
      }
      this.gettingUser = true
      const req = await disputeApi(this.axios).getAllNoUser({
        params: {
          keyword: this.searchKeyword,
          populate: ['complainant', 'respondent'],
          limit: 0,
          offset: 0,
        },
      })
      this.disputes = req.data.data
      this.gettingDispute = false
    },
    async getDispute(pagination) {
      this.gettingDispute = true
      this.pagination.offset = (pagination.current - 1) * pagination.limit
      const req = await disputeApi(this.axios).getAllNoUser({
        params: {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          populate: ['complainant', 'respondent'],
        },
      })
      this.disputes = req.data.data
      this.pagination = { ...this.pagination, ...req.data.meta }
      this.pagination.page =
        Math.ceil(this.pagination.total / this.pagination.limit) === Infinity
          ? 0
          : Math.ceil(this.pagination.total / this.pagination.limit)
      this.gettingDispute = false
    },
    showDrawer(user) {
      this.user = user
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.user = {}
    },
  },
}
</script>
